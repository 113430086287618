import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import FaqAccordion from "../../../components/faq/faq-accordion";
import Icon from "../../../components/custom-widgets/icon";

// Helpers
import getSortedFaqDataByCategory from "../../../helpers/getSortedFaqDataByCategory";

import lookIcon from "../../../images/icons/look-icon.svg";
import goIcon from "../../../images/icons/go-icon.svg";
import tapIcon from "../../../images/icons/tap-icon.svg";

import styles from "./index.module.scss";

const DebitCards = () => {
  const { sortedFaqs, categoryFootnotes, schema } = getSortedFaqDataByCategory(["Debit Cards"]);
  const title = "WaFd Bank Debit Cards";

  const seoData = {
    title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Learn about WaFd Bank's debit and Seattle Kraken debit cards. Visit our FAQs page for more information."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/debit-cards"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-debit-cards-080323.jpg"
      }
    ],
    schema
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Debit Cards"
    }
  ];

  const faqAccordionData = {
    id: "debit-cards-faq-section",
    // pass faqData instead of faqCategoryNames
    faqData: {
      sortedFaqs,
      categoryFootnotes
    }
  };

  const IntroSection = () => {
    return (
      <section className="container">
        <h1>Debit Cards</h1>
        <h3 className="text-green-60">Open a checking account and choose your Debit Card!</h3>
        <h4>
          WaFd Bank's debit cards include EMV chip technology and are enabled for{" "}
          <Link id="contactless-payments-anchor-link" to="/personal-banking/debit-cards#contactless-payments-section">
            contactless payments
          </Link>{" "}
          at thousands of contactless-enabled checkouts, providing for a fast and secure way to "tap" and pay, without
          handing over or inserting your card.
        </h4>
        <h4>
          Our cards include surcharge-free access to thousands of MoneyPass<sup>&reg;</sup> ATMs around the country and{" "}
          <Link to="/personal-banking/debit-cards#visa-purchase-alerts-section" id="visa-purchase-alerts-anchor-link">
            Visa Purchase Alerts
          </Link>
          , giving you greater access and control of your money.
        </h4>

        <h4 className="font-weight-bold mb-0">Have Questions?</h4>
        <h4 className="mb-0">
          See our{" "}
          <Link id="debit-card-faq-anchor-link" to="/personal-banking/debit-cards#debit-cards-faq-section">
            Debit Card FAQs
          </Link>
          .
        </h4>
      </section>
    );
  };

  const CardDisplaySection = () => {
    return (
      <section className="bg-light pt-4 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-5 mb-md-4 col-md-6 col-lg-3">
              <h3 className="text-green-60 mb-0">
                Choose Between Classic, Seattle Seawolves & Seattle Kraken Debit Cards
              </h3>
            </div>
            <div className="col-12 mb-5 mb-md-4 col-md-6 col-lg-3 text-center">
              <StaticImage
                src="../../../images/credit-cards/thumbnail-debit-card-consumer-horizontal.jpg"
                alt="WaFd Bank Consumer Debit Card."
                placeholder="blurred"
                className={`mb-3 ${styles.debitCardImg}`}
                quality="100"
              />
              <Link
                to="/open-an-account"
                id="standard-debit-card-open-account-link"
                className="btn btn-primary stretched-link w-100 w-sm-auto"
              >
                Open an Account
              </Link>
            </div>
            <div className="col-12 mb-5 mb-md-4 col-md-6 col-lg-3 text-center">
              <StaticImage
                src="../../../images/credit-cards/thumbnail-consumer-seawolves-horizontal.jpg"
                alt="Seawolves Debit Card"
                placeholder="blurred"
                className={`mb-3 ${styles.debitCardImg}`}
                quality="100"
              />
              <Link
                to="/open-an-account"
                id="seawolves-debit-card-open-account-link"
                className="btn btn-primary stretched-link w-100 w-sm-auto"
              >
                Open an Account
              </Link>
            </div>
            <div className="col-12 mb-5 mb-md-4 col-md-6 col-lg-3 text-center">
              <StaticImage
                src="../../../images/credit-cards/thumbnail-debit-kraken-horizontal.jpg"
                alt="Kraken Debit Card"
                placeholder="blurred"
                quality="100"
                className={`mb-3 ${styles.debitCardImg}`}
              />
              <Link
                to="/open-an-account"
                id="kraken-debit-card-open-account-link"
                className="btn btn-primary stretched-link w-100 w-sm-auto"
              >
                Open an Account
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const featuresData = [
    {
      iconSrc: lookIcon,
      title: "Look",
      text: "Look for the Contactless Symbol at the checkout terminal for a convenient and secure payment method."
    },
    {
      iconSrc: tapIcon,
      title: "Tap",
      text: "Simply tap your WaFd Bank contactless card on the checkout terminal near the Contactless Symbol."
    },
    {
      iconSrc: goIcon,
      title: "Go",
      text: "Your payment is securely processed in seconds. Once your payment is processed, you're good to go!"
    }
  ];

  const FeatureItem = ({ iconSrc, title, text }) => {
    return (
      <div className="row">
        <div className="col-auto">
          <img src={iconSrc} className={`mb-4 ${styles.listGreenIcon}`} alt={`${title}-icon`} />
        </div>
        <div className="col col-lg-8">
          <h4 className="text-green-60 mb-0">{title}</h4>
          <p>{text}</p>
        </div>
      </div>
    );
  };

  const FeaturesSection = () => {
    return (
      <section id="contactless-payments-section" className="container pb-0">
        <div className="row">
          <div className="col-12 col-md-6">
            <h3 className="mb-4">Use your WaFd Bank contactless card to make payments</h3>
            {featuresData.map((data, index) => {
              return <FeatureItem {...data} key={index} />;
            })}
          </div>
          <div className="col-12 col-md-6">
            <StaticImage
              src="../../../images/credit-cards/thumbnail-digit-cards-digital-wallet-071023.jpg"
              alt="Scanning digital wallet with WaFd card with a hand-held terminal."
              placeholder="blurred"
              quality="100"
              className="mb-3"
            />

            <h3>Digital Wallet</h3>
            <p>
              A digital debit card easily connects your personal bank account to your digital wallet for instant,
              contactless, and secure shopping wherever you go.{" "}
              <Link
                className="text-decoration-none"
                id="digital-debit-card-link"
                to="/personal-banking/debit-cards/digital-debit-card"
              >
                Digital Debit Cards in Your Digital Wallet
                <Icon name="arrow-right" class="ml-1" />
              </Link>
            </p>
          </div>
        </div>
        <hr className="my-0" />
      </section>
    );
  };

  const AlertsSection = () => {
    return (
      <section id="visa-purchase-alerts-section" className="container pt-4 pb-0">
        <h3>Visa Debit Card purchase alerts</h3>
        <div className="row">
          <div className="col-12 col-md-6">
            <p>
              Keep your account more secure. Visa Purchase Alerts on your debit card allow you to know where your money
              is going, so that you can stay on top of your account activity. After enrolling your card with Visa,
              you'll get real-time email alerts whenever your purchase meets the criteria you select during enrollment.
            </p>
            <p className="text-sm text-gray-60 mb-3">
              Please note: Purchase alerts will be delivered via email directly from Visa. Alerts will be sent only for
              transactions that are completed through the Visa network; not all types of transactions will trigger an
              email alert.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="font-weight-bold text-green-60 mb-2">Follow 3 steps to enroll:</p>
            <ol className="pl-4 mb-3">
              <li>
                Visit{" "}
                <a
                  id="visa-purchase-alerts-external-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://usa.visa.com/pay-with-visa/featured-technologies/purchase-alerts.html"
                >
                  Visa.com/PurchaseAlerts
                </a>
                .
              </li>
              <li>
                Register your information. For security purposes, you may be required to input a verification code.
              </li>
              <li>
                Choose the notification triggers that match your needs, such as transactions exceeding a set dollar
                amount, or any international purchases.
              </li>
            </ol>
          </div>
        </div>
      </section>
    );
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />

      <IntroSection />
      <CardDisplaySection />
      <FeaturesSection />
      <AlertsSection />

      <FaqAccordion {...faqAccordionData} />

      <div className="container mb-4">
        <p className="text-sm text-gray-60">
          The Contactless Symbol and Contactless Indicator are trademarks owned and used with permission of EMVCo, LLC
        </p>
      </div>
    </SecondaryLanding>
  );
};

export default DebitCards;
